import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/fundraising',
        },
        {
            path: '/fundraising',
            name: 'fundraising',
            component: () => import(
                /* webpackChunkName: "fundraising" */
                './views/igo/PoolList'
                ),
            meta: {
                title: '募资'
            }
        },
        {
            path: '/ethigo',
            name: 'ethigo',
            component: () => import(
                /* webpackChunkName: "ethigo" */
                './views/fundraising/eth/Index.vue'
                ),
            meta: {
                title: '募资'
            }
        },
        {
            path: '/avaxigo',
            name: 'avaxigo',
            component: () => import(
                /* webpackChunkName: "avaxigo" */
                './views/fundraising/avax/Index.vue'
                ),
            meta: {
                title: '募资'
            }
        },
        {
            path: '/maticigo',
            name: 'maticigo',
            component: () => import(
                /* webpackChunkName: "maticigo" */
                './views/fundraising/matic/Index.vue'
                ),
            meta: {
                title: '募资'
            }
        },
        {
            path: '/pool',
            name: 'pool',
            component: () => import(
                /* webpackChunkName: "pool" */
                './views/igo/PoolDetail.vue'
                ),
            meta: {
                title: '募资池'
            }
        },
        {
            path: '/mining',
            name: 'mining',
            component: () => import(
                /* webpackChunkName: "mining" */
                './views/farms/ProjectList.vue'
                ),
            meta: {
                title: '挖矿'
            }
        },
        {
            path: '/ranking',
            name: 'ranking',
            component: () => import(
                /* webpackChunkName: "ranking" */
                './views/ranking/Index.vue'
                ),
            meta: {
                title: '排行榜'
            }
        }
    ]
});

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router;
