import Vue from 'vue';
import Vuex from 'vuex';
import * as metaMaskTools from "./tools/chain";
/*语言包*/
import lang_zh from './tools/lang_zh.js'
import lang_en from './tools/lang_en.js'
import poolWhiteList from "./consts/poolWhiteList";
import Axios from "axios";

Vue.use(Vuex);

const initState = {
    lang: {cn: lang_zh, en: lang_en},
    languageType: 'en',
    //登录信息，必须初始化
    accounts: [],
    // 连接钱包窗口
    connectWalletShow: false,
    dialogContact: false,
    dialogInviteFriends: false,
    // 弹窗
    toastShow: false,
    // 0确认中... 1交易广播中... 2交易成功 3交易失败 4交易取消
    toastShowType: 0,
    // 交易成功后刷新hash，用来打开查看交易结果
    transactionHash: '',
    // 系统信息
    systemInfo: {
        cheersToHarvest: 0,
        cheersInWallet: 0,
        cheersPrice: 'TBA',
        cheersMarketCap: 'TBA',
        TotalValueLocked: 0,
        cheersInCirculation: 'TBA',
        cheersTotalSupply: 'TBA'
    },
    // 平台币价格地址
    priceAddress: '0xee244c7e1e92e803af29137df6fe623a8311bb7b',
    cheersPoolArr: [
        '0x0a62D95739958001C7eeA5fDDbcF3ca7fb4494ba',
        '0xD6A10407665f0C8F9b63e330d36F2845FCE37B18',
        '0xD4E416f7AAfE0eE456f6936cAd9f3bf9012E694f',
        '0xfBEf1bD32e515D87C8b755ce59edfE64B8B4DDb5',
        '0x4CFe94B7A7609E00389B875a7F7B13d89954507A'
    ],
    // 矿池地址
    orePoolArr: [
        // 测试
        // '0x87fFDF2c2582AB80F6033d23964E2e36099cDe96',
        // '0x1c5d87AE7B365BFb2e26FdE243f5a46399C8a4CC',
        // '0x7DF262ED051bB0fa1E69a22D43917Dcf2e05c41a'
        // 正式
        '0xb923919fa6Df7675aa33069425aD42878BF39206',
        '0xd11EC60CeC0FcB1b74D9Fb15cDa0aF65e7882e35',
        '0xa0668C22d2808e7d83c8a3B16A45052aEb2F0768',
        '0x5241CF262C73f321C12683196B9a83C6f444e2ad',
        '0x0a62D95739958001C7eeA5fDDbcF3ca7fb4494ba',
        '0xD6A10407665f0C8F9b63e330d36F2845FCE37B18',
        '0xD4E416f7AAfE0eE456f6936cAd9f3bf9012E694f',
        '0x2EAAF0fBECf731A72E589DE08ef6F73766EF286e',
        '0x9eaf92e7F1d291fC53289322CDBcF8751A27CB50',
        '0x88FE77Bd9139387533E5447Fe99fD4Df7a6EaE9e',
        '0xfBEf1bD32e515D87C8b755ce59edfE64B8B4DDb5',
        '0x9344D3bf77e3ecd6CB8666A4E78FD26Fe5a1946f',
        '0xf3aAB863b69098FD191c1a20fCB9ac8b1Af42be7',
        '0xfaE64A6691dA9eD1cBd700c1E0d3D8625B865856',
        '0x825CDDD8B76b53D665952F19c29C902c663501Dc',
        '0x1Dd1D8972C3F803DC2333cF6B613A827651d6263',
        '0x4CFe94B7A7609E00389B875a7F7B13d89954507A'
    ],
    // 私募池信息
    poolDetails: {
        "Dx Spot#2": {
            "poolInformation": {
                TokenDistribution: "Airdrop",
                TotalFundsSwapped: true,
                TotalUsersParticipated: false,
                IsHardCap: true,
                HardCap: "25000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "DXS",
                Network: "BSC",
                TokenAddress: "TBA",
                TotalSupply: "1000000000",
                Decimals: "8"
            }
        },
        "Dx Spot": {
            "poolInformation": {
                TokenDistribution: "Airdrop",
                TotalFundsSwapped: true,
                TotalUsersParticipated: false,
                IsHardCap: true,
                HardCap: "25000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "DXS",
                Network: "BSC",
                TokenAddress: "TBA",
                TotalSupply: "1000000000",
                Decimals: "8"
            }
        },
        "Duckie Land": {
            "poolInformation": {
                TokenDistribution: "Claim",
                TotalFundsSwapped: true,
                TotalUsersParticipated: false,
                IsHardCap: true,
                HardCap: "50000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "MMETA",
                Network: "BSC",
                TokenAddress: "TBA",
                TotalSupply: "100000000",
                Decimals: "18"
            }
        },
        "Duckie Land#2": {
            "poolInformation": {
                TokenDistribution: "Claim",
                TotalFundsSwapped: true,
                TotalUsersParticipated: false,
                IsHardCap: true,
                HardCap: "50000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "MMETA",
                Network: "BSC",
                TokenAddress: "TBA",
                TotalSupply: "100000000",
                Decimals: "18"
            }
        },
        "TrustFi": {
            "poolInformation": {
                TokenDistribution: "Claim",
                TotalFundsSwapped: true,
                TotalUsersParticipated: true,
                IsHardCap: true,
                HardCap: "1000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "TFI",
                Network: "BSC",
                TokenAddress: "0x7565ab68D3F9DaDff127F864103C8c706Cf28235",
                TotalSupply: "18",
                Decimals: "18"
            }
        },
        "CHEERS#1": {
            "poolInformation": {
                TokenDistribution: "Claim",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: false,
                HardCap: "",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "CHEERS",
                Network: "BSC",
                TokenAddress: "",
                TotalSupply: "18",
                Decimals: ""
            }
        },
        "CHEERS#2": {
            "poolInformation": {
                TokenDistribution: "Claim",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: false,
                HardCap: "",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "CHEERS",
                Network: "BSC",
                TokenAddress: "",
                TotalSupply: "18",
                Decimals: ""
            }
        },
        "CHEERS#3": {
            "poolInformation": {
                TokenDistribution: "Claim",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: false,
                HardCap: "",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "CHEERS",
                Network: "BSC",
                TokenAddress: "",
                TotalSupply: "18",
                Decimals: ""
            }
        },
        "Meland.ai": {
            "poolInformation": {
                TokenDistribution: "",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: false,
                HardCap: "",
                AccessType: ""
            },
            "tokenInformation": {
                TokenSymbol: "",
                Network: "",
                TokenAddress: "",
                TotalSupply: "",
                Decimals: ""
            }
        },
        "Battle Saga": {
            "poolInformation": {
                TokenDistribution: "",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: false,
                HardCap: "",
                AccessType: ""
            },
            "tokenInformation": {
                TokenSymbol: "",
                Network: "",
                TokenAddress: "",
                TotalSupply: "",
                Decimals: ""
            }
        },
        "Light Year": {
            "poolInformation": {
                TokenDistribution: "Claim",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: true,
                HardCap: "50000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "LC",
                Network: "BSC",
                TokenAddress: "TBA",
                TotalSupply: "100000000",
                Decimals: "18"
            }
        },
        "Kingdom Karnage": {
            "poolInformation": {
                TokenDistribution: "Claim",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: true,
                HardCap: "15000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "KKT",
                Network: "BSC",
                TokenAddress: "TBA",
                TotalSupply: "1,000,000,000",
                Decimals: "18"
            }
        },
        "MogWar": {
            "poolInformation": {
                TokenDistribution: "Claim",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: true,
                HardCap: "25000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "MONX",
                Network: "BSC",
                TokenAddress: "TBA",
                TotalSupply: "",
                Decimals: "18"
            }
        },
        "Himo World": {
            "poolInformation": {
                TokenDistribution: "Airdrop",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: true,
                HardCap: "15000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "HIMO",
                Network: "BSC",
                TokenAddress: "TBA",
                TotalSupply: "500000000",
                Decimals: "18"
            }
        },
        "Pandora": {
            "poolInformation": {
                TokenDistribution: "Airdrop",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: true,
                HardCap: "15000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "DORA",
                Network: "BSC",
                TokenAddress: "TBA",
                TotalSupply: "2500000000",
                Decimals: "18"
            }
        },
        "Pandora#2": {
            "poolInformation": {
                TokenDistribution: "Airdrop",
                TotalFundsSwapped: false,
                TotalUsersParticipated: false,
                IsHardCap: true,
                HardCap: "15000",
                AccessType: "Whitelist"
            },
            "tokenInformation": {
                TokenSymbol: "DORA",
                Network: "BSC",
                TokenAddress: "TBA",
                TotalSupply: "2500000000",
                Decimals: "18"
            }
        }
    },
    schedule: {
        "Dx Spot#2": [
            {round: "Vesting Schedule", opens: "25% at TGE, then 25% for month 2,3,4", closes: ""}
        ],
        "Dx Spot": [
            {round: "Vesting Schedule", opens: "25% at TGE, then 25% for month 2,3,4", closes: ""}
        ],
        "Duckie Land": [
            {round: "Vesting Schedule", opens: "20% at TGE, then unlock monthly for 4 months。", closes: ""}
        ],
        "Duckie Land#2": [
            {round: "Vesting Schedule", opens: "20% at TGE, then unlock monthly for 4 months。", closes: ""}
        ],
        "TrustFi": [
            {round: "Funds Commit", opens: "8th of October 12AM UTC", closes: "8th of October 1PM UTC"},
            {round: "Token Claim", opens: "8th of October 01:05PM UTC", closes: ""},
            {round: "Funds Claim", opens: "8th of October 01:05PM UTC", closes: ""}
        ],
        "CHEERS#1": [
            {round: "Funds Commit", opens: "28th of Nov. 10AM UTC", closes: "28th of Nov. 11AM UTC"},
            {round: "Token Claim", opens: "29th of Nov. 3:30PM UTC", closes: ""},
            {round: "Vesting Schedule", opens: "8% unlocked at TGE, then 8% will be released every week.", closes: ""}
        ],
        "CHEERS#2": [
            {round: "Funds Commit", opens: "28th of Nov. 10AM UTC", closes: "28th of Nov. 11AM UTC"},
            {round: "Token Claim", opens: "29th of Nov. 3:30PM UTC", closes: ""},
            {
                round: "Vesting Schedule",
                opens: "25% unlocked at TGE, then 25% will be released every 2 weeks.",
                closes: ""
            }
        ],
        "CHEERS#3": [
            {round: "Funds Commit", opens: "29th of Nov. 10AM UTC", closes: "29th of Nov. 1PM UTC"},
            {round: "Token Claim", opens: "29th of Nov. 3:30PM UTC", closes: ""},
            {round: "Vesting Schedule", opens: "8% unlocked at TGE, then 8% will be released every week.", closes: ""}
        ],
        "Meland.ai": [
            {round: "Funds Commit", opens: "", closes: ""},
            {round: "Token Claim", opens: "", closes: ""},
            {round: "Vesting Schedule", opens: "", closes: ""}
        ],
        "Battle Saga": [
            {round: "Funds Commit", opens: "", closes: ""},
            {round: "Token Claim", opens: "", closes: ""},
            {round: "Vesting Schedule", opens: "", closes: ""}
        ],
        "Light Year": [
            {round: "Funds Commit", opens: "29th of Dec.10AM UTC", closes: "29th of Dec.1PM UTC"},
            {round: "Token Claim", opens: "30th of Dec.10:30AM UTC", closes: ""},
            {round: "Funds Claim", opens: "", closes: ""}
        ],
        "Kingdom Karnage": [
            {round: "10% @ TGE 1 Month Cliff 9 Months Daily Vesting", opens: "", closes: ""}
        ],
        "MogWar": [
            {round: "8% at TGE, lock for 2 months, 18 mo. linear vesting", opens: "", closes: ""}
        ],
        "Himo World": [
            {round: "Funds Commit", opens: "23rd of Jan.12PM UTC", closes: "23rd of Jan.3PM UTC"},
            {round: "Token Claim", opens: "24th of Jan.12PM UTC", closes: ""},
            {round: "Funds Claim", opens: "", closes: ""}
        ],
        "Pandora": [
            {round: "Funds Commit", opens: "25th of Jan.12PM UTC", closes: "25th of Jan.3PM UTC"},
            {round: "Token Claim", opens: "27th of Jan.", closes: ""},
            {round: "Funds Claim", opens: "", closes: ""}
        ],
        "Pandora#2": [
            {round: "Funds Commit", opens: "26th of Jan.12PM UTC", closes: "26th of Jan.3PM UTC"},
            {round: "Token Claim", opens: "27th of Jan.", closes: ""},
            {round: "Funds Claim", opens: "", closes: ""}
        ]
    },
    aboutProject: {
        "Dx Spot#2": {
            linka: "https://www.dxspot.io/",
            linkb: "https://twitter.com/DxSpotOfficial",
            linkc: "https://t.me/DxSpotGroup",
            linkd: "https://medium.com/@DxSpot",
            introduce: 'Dx Spot is an artificial intelligence (AI) trading platform for cryptocurrency exchanges, on the Playstore, it’s now possible to use and download.Dx Spot creates DXS Tokens for its ecosystem’s tools.In the metaverse, Dx Spot will also work on DeFi, NFT, Gamefi, and AI initiatives.DXS tokens can be used for membership fees, fuel gas top-ups, game character purchases, and staking in the Dx Spot application ecosystem.'
        },
        "Dx Spot": {
            linka: "https://www.dxspot.io/",
            linkb: "https://twitter.com/DxSpotOfficial",
            linkc: "https://t.me/DxSpotGroup",
            linkd: "https://medium.com/@DxSpot",
            introduce: 'Dx Spot is an artificial intelligence (AI) trading platform for cryptocurrency exchanges, on the Playstore, it’s now possible to use and download.Dx Spot creates DXS Tokens for its ecosystem’s tools.In the metaverse, Dx Spot will also work on DeFi, NFT, Gamefi, and AI initiatives.DXS tokens can be used for membership fees, fuel gas top-ups, game character purchases, and staking in the Dx Spot application ecosystem.'
        },
        "Duckie Land": {
            linka: "https://duckie.land/",
            linkb: "https://twitter.com/duckienft",
            linkc: "https://t.me/duckienft_global",
            linkd: "https://medium.com/@duckienft",
            introduce: "Duckie Land allows players to own in-game assets and boost their worth by actively playing the game.Players will gain prizes for participating in the in-game economy, as well as add value to the ecosystem and other players.The rewards for these digital assets are cryptocurrencies and in-game resources that are tokenized on the blockchain, which could become a new revenue stream for many people, especially during the Covid-19 pandemic."
        },
        "Duckie Land#2": {
            linka: "https://duckie.land/",
            linkb: "https://twitter.com/duckienft",
            linkc: "https://t.me/duckienft_global",
            linkd: "https://medium.com/@duckienft",
            introduce: "Duckie Land allows players to own in-game assets and boost their worth by actively playing the game.Players will gain prizes for participating in the in-game economy, as well as add value to the ecosystem and other players.The rewards for these digital assets are cryptocurrencies and in-game resources that are tokenized on the blockchain, which could become a new revenue stream for many people, especially during the Covid-19 pandemic."
        },
        "TrustFi": {
            linka: "https://trustfi.org/",
            linkb: "https://twitter.com/trustfiorg",
            linkc: "https://t.me/trustfi_fans",
            linkd: "https://medium.com/@trustfi",
            introduce: "TrustFi Network is a decentralized BaaS solution for DeFi market based on multichain environment, and focuses on early crypto assets issuance, liquidity management, community activities and DAO governance to unlock the potential of DeFi."
        },
        "CHEERS#1": {
            linka: "https://cheersland.org/",
            linkb: "https://twitter.com/cheers_land",
            linkc: "https://t.me/cheersland_news",
            linkd: "https://medium.com/cheersland",
            introduce: "CheersLand is a scalable GameFi Metaverse Aggregator where anyone can monetize their gaming experiences and social networks. CheersLand runs the GameFi-as-a-Service model, dual-driven in Play to Earn and Build to Earn, with the product portfolio of Gamified Launchpad, Multi-game Universe, Multi-asset Staking Platform and NFT Market, and powered developing new users by the invitation mechanism, which builds a unique and diversified GameFi ecosystem."
        },
        "CHEERS#2": {
            linka: "https://cheersland.org/",
            linkb: "https://twitter.com/cheers_land",
            linkc: "https://t.me/cheersland_news",
            linkd: "https://medium.com/cheersland",
            introduce: "CheersLand is a scalable GameFi Metaverse Aggregator where anyone can monetize their gaming experiences and social networks. CheersLand runs the GameFi-as-a-Service model, dual-driven in Play to Earn and Build to Earn, with the product portfolio of Gamified Launchpad, Multi-game Universe, Multi-asset Staking Platform and NFT Market, and powered developing new users by the invitation mechanism, which builds a unique and diversified GameFi ecosystem."
        },
        "CHEERS#3": {
            linka: "https://cheersland.org/",
            linkb: "https://twitter.com/cheers_land",
            linkc: "https://t.me/cheersland_news",
            linkd: "https://medium.com/cheersland",
            introduce: "CheersLand is a scalable GameFi Metaverse Aggregator where anyone can monetize their gaming experiences and social networks. CheersLand runs the GameFi-as-a-Service model, dual-driven in Play to Earn and Build to Earn, with the product portfolio of Gamified Launchpad, Multi-game Universe, Multi-asset Staking Platform and NFT Market, and powered developing new users by the invitation mechanism, which builds a unique and diversified GameFi ecosystem."
        },
        "Meland.ai": {
            linka: "https://meland.ai/",
            linkb: "https://twitter.com/meland_ai",
            linkc: "https://t.me/melandaiWorld",
            linkd: "https://medium.com/@Meland.ai",
            introduce: "MeLand.ai is the first Learn-to-Earn decentralized platform where players can challenge quizzes, create quizzes, build, own and monetize their gaming experience in the Polygon blockchain, token will be on BSC, called MELD."
        },
        "Battle Saga": {
            linka: "https://www.battlesaga.io/",
            linkb: "https://twitter.com/BattleSagaio",
            linkc: "https://t.me/BattleSaga_Official",
            linkd: "https://medium.com/@BattleSagaOfficial",
            introduce: "Battle Saga is a Clash of Clan inspired decentralized Earn-Without-Playing(EWP) strategic metaverse game implementing innovative DAO system for the best user experience and adaptability."
        },
        "Light Year": {
            linka: "https://lightyear.game/home",
            linkb: "https://twitter.com/lightyear_game",
            linkc: "https://twitter.com/lightyear_game",
            linkd: "https://medium.com/@lightyear-game",
            introduce: "Light Year is a blockchain based space strategy game. Mining natural resources, crafting space ships, battling and trading with other players, joining alliances or nations, the game brings players the wildest experience of space exploration. Moreover, under the hood, it’s a play-to-earn DeFi farming protocol that maximizes users’ return from staking their crypto assets."
        },
        "Kingdom Karnage": {
            linka: "https://www.kingdomkarnage.com/",
            linkb: "https://twitter.com/kingdom_karnage",
            linkc: "https://t.me/KingdomKarnage",
            linkd: "https://medium.com/kingdomkarnage",
            introduce: "Kingdom Karnage is an Animated Combat NFT Trading Card Game currently playable cross platform."
        },
        "MogWar": {
            linka: "https://mogwar.io/",
            linkb: "https://t.me/mogwario",
            linkc: "https://twitter.com/mogwar_io",
            linkd: "https://mogwar.medium.com/",
            introduce: "Monster Of God is a strategy game combining Idle RPG specials and Autobattler. It is connected with blockchain technology, in which players can earn Monster Soul Potion (MSP) through PvP/PvE battles among Monsters and collect MONX coins through in-game tournament rewards."
        },
        "Himo World": {
            linka: "https://himo.world",
            linkb: "https://t.me/HimoWorldOfficial",
            linkc: "https://twitter.com/himoworldglobal?s=11",
            linkd: "",
            introduce: "Himo World is an NFT tile-matching & deck building strategic puzzle game. It features Free-to-Play (F2P) and Play-to-Earn (P2E) mechanisms interwoven to Rogue-like PvE content and exciting PvP modes with multi-tier Tournaments."
        },
        "Pandora": {
            linka: "https://pandoras.world/",
            linkb: "https://t.me/DiscoverPandora",
            linkc: "https://twitter.com/discoverpandora",
            linkd: "https://medium.com/@DiscoverPandora/",
            introduce: "Pandora is a P2E open world exploration game where players can build their character and explore the world with friends; discovering new environments, species, equipment and much more. This is a blockchain-based Sandbox game that has many forms of PvE and PvP interaction."
        },
        "Pandora#2": {
            linka: "https://pandoras.world/",
            linkb: "https://t.me/DiscoverPandora",
            linkc: "https://twitter.com/discoverpandora",
            linkd: "https://medium.com/@DiscoverPandora/",
            introduce: "Pandora is a P2E open world exploration game where players can build their character and explore the world with friends; discovering new environments, species, equipment and much more. This is a blockchain-based Sandbox game that has many forms of PvE and PvP interaction."
        }
    },
    whitelistedGamers: {
        "TrustFi": [
            {
                ranking: 1,
                name: "CHEERS",
                address: "0xe4ed6c30ddb9dc1ef587dce1c3e9447aec48ed21",
                allocationCap: "250 USDT"
            },
            {
                ranking: 2,
                name: "CHEERS",
                address: "0x94ed2367c9d90d255fb3fcff945f87fe398c1182",
                allocationCap: "250 USDT"
            },
            {
                ranking: 3,
                name: "CHEERS",
                address: "0x67c6f7f4ec6c0950596c30da228970276925bddc",
                allocationCap: "250 USDT"
            },
            {
                ranking: 4,
                name: "CHEERS",
                address: "0x8d85dab177c792983875ab10f7289ac331918ed8",
                allocationCap: "250 USDT"
            },
            {
                ranking: 5,
                name: "CHEERS",
                address: "0xe2919961e0b5aafec3d721d3fbcb0bd5f4a1013a",
                allocationCap: "250 USDT"
            },
            {
                ranking: 6,
                name: "CHEERS",
                address: "0xb27a4a41bfb36ad2f6475ce344bc33ce564a4fb7",
                allocationCap: "250 USDT"
            },
            {
                ranking: 7,
                name: "CHEERS",
                address: "0xcc4979efa534815c65613df0c6e175deee9e139d",
                allocationCap: "250 USDT"
            },
            {
                ranking: 8,
                name: "CHEERS",
                address: "0xfc31a8ab56cfb7ba34c40879418131f162bd5892",
                allocationCap: "250 USDT"
            },
            {
                ranking: 9,
                name: "CHEERS",
                address: "0x8ebc21ff6963819d2d635490342408900741d4dd",
                allocationCap: "250 USDT"
            },
            {
                ranking: 10,
                name: "CHEERS",
                address: "0x57de5fcd6a8b8f13681ca4a0f63244fdda571222",
                allocationCap: "250 USDT"
            },
            {
                ranking: 11,
                name: "CHEERS",
                address: "0xB03Cb873C3f283308aeB114909464Eb706a555F6",
                allocationCap: "500 USDT"
            },
            {
                ranking: 12,
                name: "CHEERS",
                address: "0xA657C1168AE85A1f5C4BA5b450D75cbF37b9264e",
                allocationCap: "250 USDT"
            }
        ],
        "CHEERS#1": [],
        "CHEERS#2": [
            {
                ranking: 1,
                name: "CHEERS",
                address: "0x3d15394b5684972acefa63b727f2a5ee3307f880",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 2,
                name: "CHEERS",
                address: "0x165fc7ad3bd1afd39ed25cc30d96716f8fb8c3ca",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 3,
                name: "CHEERS",
                address: "0xf85a66df175f3a6b30883e2d56ca8e48fbd79fe9",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 4,
                name: "CHEERS",
                address: "0xb4ac2e801b497544086a5f8a0f828e90c819428f",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 5,
                name: "CHEERS",
                address: "0x67c6f7f4ec6c0950596c30da228970276925bddc",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 6,
                name: "CHEERS",
                address: "0x5664adc9a0ca0eb291973d5a3741f8b2e7e82bcd",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 7,
                name: "CHEERS",
                address: "0x8d85dab177c792983875ab10f7289ac331918ed8",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 8,
                name: "CHEERS",
                address: "0x7f774ea3e0331e50d78a859282bedeeb0a0a54a3",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 9,
                name: "CHEERS",
                address: "0x6418cc2a4b0b6f0b24354578ad34fe33466b8ffc",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 10,
                name: "CHEERS",
                address: "0x1521d8308cab404b824fdb22694372ae2c549655",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 11,
                name: "CHEERS",
                address: "0x54958929b9ec9e7e44eb3cd9be19a5332bd6fb4e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 12,
                name: "CHEERS",
                address: "0x41a8a329e412045b191a2814dcdc2cb74c5ba757",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 13,
                name: "CHEERS",
                address: "0x15362231aaec892fba457ea8b8812de15d42ff7c",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 14,
                name: "CHEERS",
                address: "0xd827f2ad9eaaa7b82565ca75c9d981d17597b628",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 15,
                name: "CHEERS",
                address: "0x5e2be51a2e94759ef7a337d15b779f9951cc8f62",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 16,
                name: "CHEERS",
                address: "0x930617a684fe57de1714e0adc17db3d3ce6b146f",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 17,
                name: "CHEERS",
                address: "0x2cdce166413542c6f0e45a69c0bee1a227a71ddf",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 18,
                name: "CHEERS",
                address: "0x786ad8ce237783f44e2d8a9b95349025f42a14da",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 19,
                name: "CHEERS",
                address: "0xa58e531cf7155c0f03c55c6d893de21ac7237e36",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 20,
                name: "CHEERS",
                address: "0xa6124b6e2bccc49a0fcc4b36c40459eccc46176f",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 21,
                name: "CHEERS",
                address: "0x28490898850a8628ee4d5889d8e21cc0058d58c4",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 22,
                name: "CHEERS",
                address: "0xc24292710fc1b904d25a6b723cec28085246e07e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 23,
                name: "CHEERS",
                address: "0xeeb800ff7589597f8f5dae0f24e32bd9dec65c3d",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 24,
                name: "CHEERS",
                address: "0xfb0cd6d272e98dbaca5f85730a7aca9d9697e2cb",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 25,
                name: "CHEERS",
                address: "0x70427deebad595dea3d032a5153b0c0cbf667283",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 26,
                name: "CHEERS",
                address: "0x1f774838d00598903b3019acec2c5b8875575ef9",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 27,
                name: "CHEERS",
                address: "0xf7a5ed06fb75311d730d4bfe88e39096ef755fe6",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 28,
                name: "CHEERS",
                address: "0x385c83f56a087b89fc4f22863fa3760ba0b441a8",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 29,
                name: "CHEERS",
                address: "0xa100beb8b4a013a4e816ca81a7af782d4bdab9cd",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 30,
                name: "CHEERS",
                address: "0x174026f67d22abaeee1b10b60da3304807b150d1",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 31,
                name: "CHEERS",
                address: "0x8d1a1fa241e4b10d010a84832ca364a6c249867a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 32,
                name: "CHEERS",
                address: "0x36857354c0fd27c5acb9c489e9d42a7612f45979",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 33,
                name: "CHEERS",
                address: "0x35e3e7f7a09c25076fcd84601ccbe6b2911773f6",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 34,
                name: "CHEERS",
                address: "0x462b98009e2048c3b58607117396676aa094c12e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 35,
                name: "CHEERS",
                address: "0x0876076d24f84bb0e037345d3725c49f62d0a50d",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 36,
                name: "CHEERS",
                address: "0x8649c631b2a744be929d557af5be3a5092d28a12",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 37,
                name: "CHEERS",
                address: "0x4b6c964467e93b8b94bd6ff92e096870dee5f13f",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 38,
                name: "CHEERS",
                address: "0x5309ccead59405eabece0bddfd93e4a981522da7",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 39,
                name: "CHEERS",
                address: "0x9c3d95a9ddc01f26d7794a27a6bf0e6644ecc530",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 40,
                name: "CHEERS",
                address: "0xcd66893b344d2e803716575c43f2e67a91171290",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 41,
                name: "CHEERS",
                address: "0x8fb15c5bfc8c161b57693bb662114cecdc36fd9a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 42,
                name: "CHEERS",
                address: "0x1de743899d9549b60addaddb5b8d8ea15f3842f5",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 43,
                name: "CHEERS",
                address: "0xa81e62a0c286f4e777ad44a89f89e413dc53b16e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 44,
                name: "CHEERS",
                address: "0xae29eec679d4c7838faf3bf1c30fa8edeb6d1457",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 45,
                name: "CHEERS",
                address: "0xcd2bff7084bb0b924cf4ade559043e355c71b3a8",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 46,
                name: "CHEERS",
                address: "0x8a11373ffca1de1b776b8bd18beff9babd9859b7",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 47,
                name: "CHEERS",
                address: "0xd2bfc941e0a0df9d510a4869f98674e4cf241a49",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 48,
                name: "CHEERS",
                address: "0xb1266138b90f8b87b08af1761a4925f7ea2f6e12",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 49,
                name: "CHEERS",
                address: "0x907dc414a2e4a854bfe365f903ebf7e063479fe2",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 50,
                name: "CHEERS",
                address: "0x06a2ce27e984f1d606e00f69245853ca75e1b6a0",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 51,
                name: "CHEERS",
                address: "0xe4ed6c30ddb9dc1ef587dce1c3e9447aec48ed21",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 52,
                name: "CHEERS",
                address: "0x94ed2367c9d90d255fb3fcff945f87fe398c1182",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 53,
                name: "CHEERS",
                address: "0x978be9e0c7cc9f5c9e8d71eef70bbaff7930b74a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 54,
                name: "CHEERS",
                address: "0x880cd46739b034a753771bc8ffe79dff7f500e35",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 55,
                name: "CHEERS",
                address: "0x3cc551c4d83a5ec0daccac73923e24a98ed4dab9",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 56,
                name: "CHEERS",
                address: "0x0f71ca78efa7bc202ecb5d2bb10125f41c7a1911",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 57,
                name: "CHEERS",
                address: "0x5f71db02168d15a18536d864586a3e1c0463e088",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 58,
                name: "CHEERS",
                address: "0x475dcb47b4d2ad9d9fde4d4b522b4330326e216e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 59,
                name: "CHEERS",
                address: "0x8bf56b6930d87381e93ca69208341ef55fbfbef6",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 60,
                name: "CHEERS",
                address: "0x9a2c2fc8213c5e574208810b3f3d0d3a54e91d94",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 61,
                name: "CHEERS",
                address: "0xa5896c88e8ad3e91fe8be767c185f47a3042e114",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 62,
                name: "CHEERS",
                address: "0x0ad06ca9011f67662cd4701b9660a36d70e9aaf0",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 63,
                name: "CHEERS",
                address: "0xe2919961e0b5aafec3d721d3fbcb0bd5f4a1013a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 64,
                name: "CHEERS",
                address: "0xb27a4a41bfb36ad2f6475ce344bc33ce564a4fb7",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 65,
                name: "CHEERS",
                address: "0xcc4979efa534815c65613df0c6e175deee9e139d",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 66,
                name: "CHEERS",
                address: "0xfc31a8ab56cfb7ba34c40879418131f162bd5892",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 67,
                name: "CHEERS",
                address: "0x80122d839753a5be1f7af4beb7f7d4ee68f8edfa",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 68,
                name: "CHEERS",
                address: "0x8ebc21ff6963819d2d635490342408900741d4dd",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 69,
                name: "CHEERS",
                address: "0xda9317748e8781cd4082073e00133cd4420e808a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 70,
                name: "CHEERS",
                address: "0x1cf5e50ef948962ce9b76c34d64442a23647cb3a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 71,
                name: "CHEERS",
                address: "0x57de5fcd6a8b8f13681ca4a0f63244fdda571222",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 72,
                name: "CHEERS",
                address: "0x0cfe255ed695fc5d37656b8b158fa8e7f31df100",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 73,
                name: "CHEERS",
                address: "0xe3daa4b09e56b9e4f948b4e0743f8cd51a076cb0",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 74,
                name: "CHEERS",
                address: "0x422cf13f0e937586821f638d2a3ec60469aa8a82",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 75,
                name: "CHEERS",
                address: "0x8d9c63b68f4c4aa58799e2f7f5de3abe5d76df26",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 76,
                name: "CHEERS",
                address: "0x5ed73c93d8ec85a08f6aadffc3910c3848907e7b",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 77,
                name: "CHEERS",
                address: "0x9f2cacaad5f48ff97af70372613ae9b7e074dabc",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 78,
                name: "CHEERS",
                address: "0xdcd83cce37cfc25221f3ba6450ebb3c87acda938",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 79,
                name: "CHEERS",
                address: "0x0d25a92bf51e974c6139ab7a7a65f5f6bb044f3d",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 80,
                name: "CHEERS",
                address: "0xe4362eaa706a937bdf0cfc2d6cf538b4867cc867",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 81,
                name: "CHEERS",
                address: "0xa74b7561609dcbd6cb4983aa1c07bd626ae7fde0",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 82,
                name: "CHEERS",
                address: "0x626aff2e42eb767ca40f23c64bcdcbbe45f88d75",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 83,
                name: "CHEERS",
                address: "0x5f6db4da0fbe6af03301436302a58385b41fa26e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 84,
                name: "CHEERS",
                address: "0x63aaa1936a34c27166c87304c80d1b90b3096d65",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 85,
                name: "CHEERS",
                address: "0x2422c6c4f34dac59cabc1204f432c2bcce0688ff",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 86,
                name: "CHEERS",
                address: "0xc73beaf0e6d1c161e99d94979b94e25157d369dd",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 87,
                name: "CHEERS",
                address: "0xeed2640cd042f3770e2e3fab44de8aeb42af24bd",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 88,
                name: "CHEERS",
                address: "0x694ecf81abc20f2b8190ef99045289aba7004f4f",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 89,
                name: "CHEERS",
                address: "0x5dd4ae42d564adcb3c7a44174c07498091190d71",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 90,
                name: "CHEERS",
                address: "0x620f53866f1a420ad108a059490c5adf4e42e3b1",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 91,
                name: "CHEERS",
                address: "0x848ee3de682de13eed7e60e482751ffddf720c68",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 92,
                name: "CHEERS",
                address: "0x81c031c9332327d6a418c521e8a08a1f7fa87331",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 93,
                name: "CHEERS",
                address: "0x12ed0c45df3a452ba4ce7e2670b753065ba92396",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 94,
                name: "CHEERS",
                address: "0xee1ed514ffb65c6394907af46e9ead0ec947b617",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 95,
                name: "CHEERS",
                address: "0xe518ba783b4f15782bd0c040c4893117078bfc02",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 96,
                name: "CHEERS",
                address: "0x505cb80ddbfb65f9a84d70719db33dcdf3a82957",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 97,
                name: "CHEERS",
                address: "0x75a25a9b8c01b09444f9fe1f10f402d48b36d23c",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 98,
                name: "CHEERS",
                address: "0x802fb4d3059f69d9a59f6158ae6fde5896d23b2e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 99,
                name: "CHEERS",
                address: "0x06fe0a7fb596948011786e07563a22b084c4e484",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 100,
                name: "CHEERS",
                address: "0x8b19e0ec3e35fbcd7c02e4a2da56758313f40bdc",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 101,
                name: "CHEERS",
                address: "0x8fb15c5bfc8c161b57693bb662114cecdc36fd9a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 102,
                name: "CHEERS",
                address: "0x07c0c96e7fd7229a6ee11c7fe93960873a9aa56a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 103,
                name: "CHEERS",
                address: "0xa58e531cf7155c0f03c55c6d893de21ac7237e36",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 104,
                name: "CHEERS",
                address: "0x31dad17e70a236d7ae40a10ca8446e422d081ddd",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 105,
                name: "CHEERS",
                address: "0xf7d31f6098297a9cdc4cd3008aca721d4a386901",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 106,
                name: "CHEERS",
                address: "0xc1eb95d097993983b629858f45df82b3da5dabbf",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 107,
                name: "CHEERS",
                address: "0x0f71ca78efa7bc202ecb5d2bb10125f41c7a1911",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 108,
                name: "CHEERS",
                address: "0x0d77cf69f020964c951a9875376cd568fa7eccf4",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 109,
                name: "CHEERS",
                address: "0x165fc7ad3bd1afd39ed25cc30d96716f8fb8c3ca",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 110,
                name: "CHEERS",
                address: "0xf728c1e557b569c66b3918dea54da634530747da",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 111,
                name: "CHEERS",
                address: "0xd67898e268662ac44280e950a4f80621bbcb55ac",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 112,
                name: "CHEERS",
                address: "0xe0d9a11c43079a6fd38d4f0f9d27282abb8c71cd",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 113,
                name: "CHEERS",
                address: "0x8285758f16871de7bd3be753c90c19752531071b",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 114,
                name: "CHEERS",
                address: "0x9aa661b69477fbad384edb52fecb9b8a0f3b7587",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 115,
                name: "CHEERS",
                address: "0xf40108e0489a38211461a4b9ad62da2e61c83a3c",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 116,
                name: "CHEERS",
                address: "0xdd379225fb8e260be8e7b6e83c5c123f56c5d94e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 117,
                name: "CHEERS",
                address: "0x719887ee95017728de1344d624b3783b4f4acad6",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 118,
                name: "CHEERS",
                address: "0xa81e62a0c286f4e777ad44a89f89e413dc53b16e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 119,
                name: "CHEERS",
                address: "0xa4aba4b4bbea97a3d14da4f4f53b097a513e46ce",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 120,
                name: "CHEERS",
                address: "0x27f1f1e7e13162eae71044a02998296cb440b979",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 121,
                name: "CHEERS",
                address: "0x49ba25d2bfe6b15a7a0c3e65490b5aaa638df6e0",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 122,
                name: "CHEERS",
                address: "0x2ac918d344c352cd3d2cb0b3fa1feff4120cf243",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 123,
                name: "CHEERS",
                address: "0x3826d068374f241a85eb37d21ebb15824a281e0b",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 124,
                name: "CHEERS",
                address: "0x528812656f788fcabe35c10f8fee0ac60603bf57",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 125,
                name: "CHEERS",
                address: "0xc7fec5c72bf986ac7c4ebbd23d32f0c1df39fff4",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 126,
                name: "CHEERS",
                address: "0x43d9297440e2810168a1337407fa3c0e2a86dde5",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 127,
                name: "CHEERS",
                address: "0xc6cc84e8618356d94a52fd3d299b6f5b21f03757",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 128,
                name: "CHEERS",
                address: "0x13c608999b5c27a01bd89f48e8c887eaf3741224",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 129,
                name: "CHEERS",
                address: "0x4fd4b7dbbed3a7173b1102e05650ed5ef52a1672",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 130,
                name: "CHEERS",
                address: "0x802fb4d3059f69d9a59f6158ae6fde5896d23b2e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 131,
                name: "CHEERS",
                address: "0x6a70319f085e5b4c64ac0573da2f4b854b498592",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 132,
                name: "CHEERS",
                address: "0x0ff65f3c24c1410c34ccef7b888d19736a036665",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 133,
                name: "CHEERS",
                address: "0x8580cbfabf4c23bdb760156fa8fc1548214fc930",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 134,
                name: "CHEERS",
                address: "0x6838aeb8daed5a5cf97d167272526d1672c61ef4",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 135,
                name: "CHEERS",
                address: "0xb49b0c6b6cec3483317132279b1ca5019e4389cf",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 136,
                name: "CHEERS",
                address: "0xdc98f31b0fb45dd04a293fdcdd15d8e3eac56568",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 137,
                name: "CHEERS",
                address: "0xb1658bd148f54c1e59d85f7fda511b558533b0ca",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 138,
                name: "CHEERS",
                address: "0x5bac214dde468e494b2020bcebef0a1f43b545c1",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 139,
                name: "CHEERS",
                address: "0xcb1a5a43de31067a65e26adddae9c7fdc9cfa513",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 140,
                name: "CHEERS",
                address: "0xd8f0db74f9d01b9cdfedc6cd687f4a871ad4b03c",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 141,
                name: "CHEERS",
                address: "0xb1266138b90f8b87b08af1761a4925f7ea2f6e12",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 142,
                name: "CHEERS",
                address: "0xcd66893b344d2e803716575c43f2e67a91171290",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 143,
                name: "CHEERS",
                address: "0xf1c8b8da9ed68cfb387ca5ae23e7621174d4d0e0",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 144,
                name: "CHEERS",
                address: "0xaba09af819963c104be4e11318a4d4f3825c22be",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 145,
                name: "CHEERS",
                address: "0x8df8e0080feaa75d435b3e2cfb0c3f39d34d9a78",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 146,
                name: "CHEERS",
                address: "0x44e48842290489638772cc53067625d15E824766",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 147,
                name: "CHEERS",
                address: "0x287f4c3fde9a1ca0973358d3b902772737edfb2b",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 148,
                name: "CHEERS",
                address: "0xeda4ef701256d8a021fd89e64f6792a9dca5778a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 149,
                name: "CHEERS",
                address: "0x462b98009e2048c3b58607117396676aa094c12e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 150,
                name: "CHEERS",
                address: "0x792f4484c9232ef166ff1fc5ba9a04675f158ff4",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 151,
                name: "CHEERS",
                address: "0x7f9975c41d07dfc6501c61da8dba6b201ee84fb7",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 152,
                name: "CHEERS",
                address: "0xd43eea8e73957c7632afe43ee154ad4a79d98181",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 153,
                name: "CHEERS",
                address: "0x3f038a905f51b314119819151ad6fb6cf91c8378",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 154,
                name: "CHEERS",
                address: "0xbf042333460ad1bc0814aa5b413b4c43fdedea03",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 155,
                name: "CHEERS",
                address: "0x5a9e91b826be005b2613441de1f2d9cfcd8b1fad",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 156,
                name: "CHEERS",
                address: "0x73356c70d14887133cfd85f3b6e916a8d1e36935",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 157,
                name: "CHEERS",
                address: "0x9e7717cea7f26be444bc7bc5508959a0d45a5569",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 158,
                name: "CHEERS",
                address: "0xd166039b001a43ec25e5b57dfc155a5d7138e262",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 159,
                name: "CHEERS",
                address: "0xb522809ff4ff878ce09bd081d75c0b84da5393b9",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 160,
                name: "CHEERS",
                address: "0xa6a26370a1303cb22a9bf5ee705e7a9ae85a4f82",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 161,
                name: "CHEERS",
                address: "0x2e2a18d0e4cb6edf7e1859a7b07b0ae7dfa30fdf",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 162,
                name: "CHEERS",
                address: "0x6d63b273ad86fd67cc947f168ed7b7a9b86c22eb",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 163,
                name: "CHEERS",
                address: "0xc7b2ecfdaede8593b370f54cb8ace39ad42f21dd",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 164,
                name: "CHEERS",
                address: "0x322fab7e6b7cbabdfa646816aff3516fa6db85a3",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 165,
                name: "CHEERS",
                address: "0xd8ae40216ce071d0cf4307f7e56ba04f0fa3c8fa",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 166,
                name: "CHEERS",
                address: "0x24c64b430eeda189803442f6f62982e111c55188",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 167,
                name: "CHEERS",
                address: "0xbc31e36e42c2eeaf8d99d95008ee4a82c13e11ea",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 168,
                name: "CHEERS",
                address: "0x2acdf606c3acd129357879aaf09e4cfeeb5f8bdf",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 169,
                name: "CHEERS",
                address: "0x86c47c7fb485b3028b569dad03970842c7a11eb1",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 170,
                name: "CHEERS",
                address: "0x9539e0aef6510b3fe486e7d7dc4013adc49f8180",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 171,
                name: "CHEERS",
                address: "0xa6ade0cfe1cba8b1664fa23930d20bcbfa26dbd1",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 172,
                name: "CHEERS",
                address: "0x5163e1f269f9e559309eda850ced2a3f356905ba",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 173,
                name: "CHEERS",
                address: "0x4b6c964467e93b8b94bd6ff92e096870dee5f13f",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 174,
                name: "CHEERS",
                address: "0x4389c066c481232f778e7f180d1682d3b421550b",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 175,
                name: "CHEERS",
                address: "0xb32515edb5edc504e05a04bf433b9502cfd22926",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 176,
                name: "CHEERS",
                address: "0x3f99af5ae3c7bafbd9183bd2cd180da3b289047b",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 177,
                name: "CHEERS",
                address: "0x46d4a5a670a1c639caa506b238b60601ae23b47a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 178,
                name: "CHEERS",
                address: "0x3d15394b5684972acefa63b727f2a5ee3307f880",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 179,
                name: "CHEERS",
                address: "0x8a11373ffca1de1b776b8bd18beff9babd9859b7",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 180,
                name: "CHEERS",
                address: "0x5fca46577dd53666e8578edd60ed86536e4beb6a",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 181,
                name: "CHEERS",
                address: "0xc380b79db82cf37fd43d1ebd9ca1b30c9fc749f2",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 182,
                name: "CHEERS",
                address: "0xbd24c106d159c66e7ae6c85d7ec29f85dd601391",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 183,
                name: "CHEERS",
                address: "0x9d7352683fba6d04817466a6c8d8ffcdc0f538f4",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 184,
                name: "CHEERS",
                address: "0x3c20d6eb358bd54418e7fd765028fbbba6741d79",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 185,
                name: "CHEERS",
                address: "0x39fa6b8951dd11ec26c18decf0600a57834ec2f7",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 186,
                name: "CHEERS",
                address: "0xf7a5ed06fb75311d730d4bfe88e39096ef755fe6",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 187,
                name: "CHEERS",
                address: "0x390b5b50b2fa9d24395f88acd704aa2c6c44f68d",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 188,
                name: "CHEERS",
                address: "0xcf3306121223852bec7948c13e36950fa3fb9d0f",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 189,
                name: "CHEERS",
                address: "0xe234dceffaa255e97f119bf6fa0f99c0ad4ad6c5",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 190,
                name: "CHEERS",
                address: "0xc68a520131904e0a958288e900df4807a1fbd2ce",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 191,
                name: "CHEERS",
                address: "0x34ebb3a2eee6ec4f44c47fc08e33e2443704095e",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 192,
                name: "CHEERS",
                address: "0x0f1a79ca2ec0368d4c3bb6c2f871ca89b87bba66",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 193,
                name: "CHEERS",
                address: "0xa05fcbf175d7454c3df18cfb10a170937bb6dc0c",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 194,
                name: "CHEERS",
                address: "0xf7b5978042d3BD1e66b5039Bd7B1c5FE562bD3A5",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 195,
                name: "CHEERS",
                address: "0x90774ab01bb5725d1ce10adf47a1b791a594279c",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 196,
                name: "CHEERS",
                address: "0x19ef27dc3c0c8f840d40322e127e37bd2a47e1c4",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 197,
                name: "CHEERS",
                address: "0xa42a26a116e3a4921faa588b43a15774047ec33b",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 198,
                name: "CHEERS",
                address: "0xeba81d9ae5eaca0cc26d492b2f9e4523bb2fc977",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 199,
                name: "CHEERS",
                address: "0x4b85caaef196c9a6b773e7b1a517c573c82480ee",
                allocationCap: "100 BUSD"
            },
            {
                ranking: 200,
                name: "CHEERS",
                address: "0x8295130009de04253863c106dcff83c60527f9b6",
                allocationCap: "100 BUSD"
            }
        ],
        "CHEERS#3": [],
        "Meland.ai": [],
        "Battle Saga": [],
        "Light Year": [],
        "Kingdom Karnage": [],
        "MogWar": [],
        "Himo World": [],
        "Pandora": [],
        "Pandora#2": []
    },
    // 远程配置的池子信息
    remotePoolInfo: [],
    // 远程配置的池子信息
    remotePoolClaimInfo: [],
};

let state = {
    ...initState
};

const mutations = {
    /*
     * 设置state
     */
    setState(state, params) {
        for (let k in params) {
            state[k] = params[k]
        }
    }
};

const axios = Axios.create({})

/* 获取所有配置 */
const actions = {
    /*
     * 退出功能
     */
    logOut({ commit, state}) {
        commit('setState', {
            accounts: []
        })
        localStorage.setItem('accounts', null);
        this.dispatch("initHomeData");
    },
    // 初始化
    async initHomeData() {
        state.systemInfo = {
            ...state.systemInfo,
            cheersToHarvest: state.accounts.length > 0 ? await metaMaskTools.earnedAll(state.cheersPoolArr, state.accounts[0]) : 0,
            cheersInWallet: state.accounts.length > 0 ? await metaMaskTools.getCheersBalanceOf(state.accounts[0]) : 0,
            cheersPrice: await metaMaskTools.getCheersPrice(state.priceAddress),
            cheersMarketCap: 11345000 * (await metaMaskTools.getCheersPrice(state.priceAddress)),
            TotalValueLocked: await metaMaskTools.getTVLAll(state.orePoolArr),
            cheersInCirculation: 11345000,
            cheersTotalSupply: await metaMaskTools.getCheersTotalSupply()
        }
    },
    // 远程拉取池子配置
    async getRemotePoolConfig({ commit, state }, account) {
        console.log('load remote config, account: ', account)
        // 拉取git上的池子配置
        const configRes = await axios.get('https://raw.githubusercontent.com/peterlimjr/launch-config/main/poolConfig.json', {
            params: {
                t: Date.now()
            }
        })
        let rawPoolList = configRes.data.poolList;
        // 白名单用户拉取测试配置
        if (account && poolWhiteList.includes(account.toLowerCase())) {
            const testConfigRes = await axios.get('https://raw.githubusercontent.com/peterlimjr/launch-config/main/poolConfigForTest.json', {
                params: {
                    t: Date.now()
                }
            })
            if (testConfigRes.data.poolList) {
                rawPoolList = [...testConfigRes.data.poolList, ...rawPoolList]
            }
        }
        // 把git配置转换一下，塞到本地配置里面去
        const poolInfoList = rawPoolList.map((pool, idx) => {
            return {
                poolIndex: 100 + idx,
                isStart: false,
                isEnd: false,
                isShow1: true,
                isShow2: false,
                isShow3: true,
                isShow4: true,
                isShow5: true,
                isLeft1: true,
                isLeft2: false,
                isLeft3: true,
                isLeft4: false,
                isPoolRefund: pool.isDisplayRefund,
                isSureClaim: pool.isDisplayClaim,
                isLink: true,
                igoType: 'many',
                poolName: pool.projectName,
                poolAddress: pool.poolContractAddress,
                stake: pool.stakeTokenName,
                tokenAddress: pool.stakeTokenAddress,
                earn: pool.earnTokenName,
                earnTokenAddress: pool.earnTokenAddress,
                earnImg: pool.logoUrl,
                igoStarts: pool.IGOStarts,
                igoEnds: pool.IGOEnds,
                claimStarts: pool.ClaimStarts,
                price: 0.00000001,
                priceText: pool.salePrice,
                hardCap: pool.hardCap,
                realization: 'TBA',
                ratio: 0,
                poolRemainingTime: 0,
                remarks: pool.poolName,
                introduce: pool.earnTokenIntroduce,
            };
        })
        const poolDetails = {}
        const schedule = {}
        const aboutProject = {}
        rawPoolList.forEach((pool) => {
            poolDetails[pool.projectName] = {
                "poolInformation": {
                    TokenDistribution: pool.tokenDistribution,
                    TotalFundsSwapped: true,
                    TotalUsersParticipated: true,
                    IsHardCap: true,
                    HardCap: pool.hardCap,
                    AccessType: pool.accessType
                },
                "tokenInformation": {
                    TokenSymbol: pool.earnTokenName,
                    Network: pool.network,
                    TokenAddress: pool.earnTokenAddress,
                    TotalSupply: pool.totalSupply,
                    Decimals: pool.decimals,
                    MinPricePerToken: pool.minPricePerToken,
                }
            }
            schedule[pool.projectName] = [
                {round: "Vesting Schedule", opens: pool.vestingSchedule, closes: ""}
            ]
            aboutProject[pool.projectName] = {
                linka: pool.website,
                linkb: pool.twitter,
                linkc: pool.telegram,
                linkd: pool.medium,
                introduce: pool.earnTokenDetailText
            }
        })
        commit('setState', {
            poolDetails: {
                ...state.poolDetails,
                ...poolDetails,
            },
            schedule: {
                ...state.schedule,
                ...schedule
            },
            aboutProject: {
                ...state.aboutProject,
                ...aboutProject
            },
            remotePoolInfo: poolInfoList
        })
    },
    // 拉取远程池子claim配置
    async getRemoteClaimConfig({ commit }) {
        const configRes = await axios.get('https://raw.githubusercontent.com/peterlimjr/launch-config/main/claim.json', {
            params: {
                t: Date.now()
            }
        })
        commit('setState', {
            remotePoolClaimInfo: configRes.data.poolList
        })
    }
};

export default new Vuex.Store({
    state,
    mutations,
    actions
})
