import Web3 from 'web3';
import store from "../store";
import Toast from "vant/lib/toast";
import 'vant/lib/toast/style';

import abiERC20 from './abiERC20';
import abiFundraisingIdoPool from './abiPoolsFinal';
import abiFundraisingIdosPool from './abiPoolsFinal';
import abiCheersLpPool from './abiCheersLpPool';

import { connectMetaMask } from './walletConnectTools'

import {
    BigNumber,
    BigNumberStr,
    BigNumberMul,
    BigNumberDiv,
    BigNumberAdd,
    BigNumberSub,
    setUnit,
    cutAccount
} from './index.js'

import {
    OpenUrlSLD,
    CheersToken,
    FundRaisingToken,
    TrustFiToken,
    MetaxToken,
    BnbToken,
    BusdToken,
    UsdtToken,
    BabyToken,
    MilkToken,
    LightYearToken,
    PolcToken,
    MoniToken,
    AogToken,
    KktToken,
    PolcBnbLpToken,
    MoniBnbLpToken,
    AogBusdLpToken,
    TfiBnbToken,
    BnbBusdToken,
    MetaxUsdtToken,
    CheersBusdToken,
    BabyUsdtToken,
    TfiUsdtToken,
    MilkUsdtBabyLpToken
} from "../config"
import abiPublicFundraisingPool from "./abiPoolsFinal";

const initWeb3hooks = async () => {
    await connectMetaMask();
    await initParams();
    store.dispatch("initHomeData");
};

const initWeb3 = async () => {
    try {
        await initWeb3hooks();
    } catch (err) {
        Toast.fail(err)
    }
}

let account = localStorage.getItem('accounts');
if (account) {
    console.log('account', account)
    initWeb3()
}

// 实例化合约
const initParams = async () => {
    let ethereum = window.ethereum;
    if (!ethereum) {
        window.globalWeb3 = new Web3(Web3.givenProvider || 'https://bsc.blockpi.network/v1/rpc/daec94cfbc7cfe8e534a01248b634a6b7f18ea44');
    } else {
        window.globalWeb3 = new Web3(ethereum);
    }
    console.log('连接成功')
    window.cheersContract = await new window.globalWeb3.eth.Contract(abiERC20, CheersToken); // cheers平台代币
    window.fundRaisingContract = await new window.globalWeb3.eth.Contract(abiERC20, FundRaisingToken); // bigo募资代币
    window.trustFiContract = await new window.globalWeb3.eth.Contract(abiERC20, TrustFiToken); // tfi代币
    window.bnbContract = await new window.globalWeb3.eth.Contract(abiERC20, BnbToken); // bnb代币
    window.busdContract = await new window.globalWeb3.eth.Contract(abiERC20, BusdToken); // busd代币
    window.usdtContract = await new window.globalWeb3.eth.Contract(abiERC20, UsdtToken); // usdt代币
};

// 弹窗
const popupPrompts = (type, hashCode) => {
    store.commit('setState', {
        toastShow: true,
        toastShowType: type,
        transactionHash: hashCode,
    })
};

const getCheersTotalSupply = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiERC20, CheersToken).methods.totalSupply().call();
            res = BigNumberDiv(res, 1e18);
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getCheersBalanceOf = async (account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await window.cheersContract.methods.balanceOf(account).call();
            res = BigNumberDiv(res, 1e18);
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getLpBalanceOf = async (token, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiERC20, token).methods.balanceOf(account).call();
            res = BigNumberDiv(res, 1e18);
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getCheersPrice = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let price;
            let cheers = await new window.globalWeb3.eth.Contract(abiERC20, CheersToken).methods.balanceOf(contract).call();
            let busd = await new window.globalWeb3.eth.Contract(abiERC20, BusdToken).methods.balanceOf(contract).call();
            price = busd / cheers
            price = parseFloat(price)
            resolve(price);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getLpPrice = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let price;
            let TFI = await new window.globalWeb3.eth.Contract(abiERC20, TrustFiToken).methods.balanceOf(TfiBnbToken).call();
            let TBNB = await new window.globalWeb3.eth.Contract(abiERC20, BnbToken).methods.balanceOf(TfiBnbToken).call();
            let BBNB = await new window.globalWeb3.eth.Contract(abiERC20, BnbToken).methods.balanceOf(BnbBusdToken).call();
            let BUSD = await new window.globalWeb3.eth.Contract(abiERC20, BusdToken).methods.balanceOf(BnbBusdToken).call();

            price = (TBNB / TFI) * (BUSD / BBNB)

            price = parseFloat(price)
            resolve(price);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getPolcPrice = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let price;
            let POLC = await new window.globalWeb3.eth.Contract(abiERC20, PolcToken).methods.balanceOf(PolcBnbLpToken).call();
            let WBNB = await new window.globalWeb3.eth.Contract(abiERC20, BnbToken).methods.balanceOf(PolcBnbLpToken).call();
            let BBNB = await new window.globalWeb3.eth.Contract(abiERC20, BnbToken).methods.balanceOf(BnbBusdToken).call();
            let BUSD = await new window.globalWeb3.eth.Contract(abiERC20, BusdToken).methods.balanceOf(BnbBusdToken).call();

            price = (WBNB / POLC) * (BUSD / BBNB)

            price = parseFloat(price)
            resolve(price);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getMoniPrice = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let price;
            let MONI = await new window.globalWeb3.eth.Contract(abiERC20, MoniToken).methods.balanceOf(MoniBnbLpToken).call();
            let WBNB = await new window.globalWeb3.eth.Contract(abiERC20, BnbToken).methods.balanceOf(MoniBnbLpToken).call();
            let BBNB = await new window.globalWeb3.eth.Contract(abiERC20, BnbToken).methods.balanceOf(BnbBusdToken).call();
            let BUSD = await new window.globalWeb3.eth.Contract(abiERC20, BusdToken).methods.balanceOf(BnbBusdToken).call();

            price = (WBNB / MONI) * (BUSD / BBNB)

            price = parseFloat(price)
            resolve(price);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getAogPrice = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let price;
            let USDT = await new window.globalWeb3.eth.Contract(abiERC20, BusdToken).methods.balanceOf(AogBusdLpToken).call();
            let AOG = await new window.globalWeb3.eth.Contract(abiERC20, AogToken).methods.balanceOf(AogBusdLpToken).call();

            console.log('USDT: ' + USDT)
            console.log('AOG: ' + AOG)

            price = (USDT / AOG)

            price = parseFloat(price)

            console.log('AogPrice: ' + price)

            resolve(price);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getMetaxLpPrice = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let price;
            let USDT = await new window.globalWeb3.eth.Contract(abiERC20, BusdToken).methods.balanceOf(MetaxUsdtToken).call();
            let METAX = await new window.globalWeb3.eth.Contract(abiERC20, MetaxToken).methods.balanceOf(MetaxUsdtToken).call();

            console.log('USDT: ' + USDT)
            console.log('METAX: ' + METAX)

            price = (USDT / METAX)

            price = parseFloat(price)

            console.log('MetaxPrice: ' + price)

            resolve(price);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getCheersBuLpPrice = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let price;

            let CHEERS = await new window.globalWeb3.eth.Contract(abiERC20, CheersToken).methods.balanceOf(CheersBusdToken).call();
            let BUSD = await new window.globalWeb3.eth.Contract(abiERC20, BusdToken).methods.balanceOf(CheersBusdToken).call();

            console.log('CHEERS: ' + CHEERS)
            console.log('BUSD: ' + BUSD)

            price = (BUSD / CHEERS)

            price = parseFloat(price)

            console.log('CheersPrice: ' + price)

            resolve(price);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getBabyLpPrice = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let price;

            let BABY = await new window.globalWeb3.eth.Contract(abiERC20, BabyToken).methods.balanceOf(BabyUsdtToken).call();
            let USDT = await new window.globalWeb3.eth.Contract(abiERC20, UsdtToken).methods.balanceOf(BabyUsdtToken).call();

            console.log('BABY: ' + BABY)
            console.log('USDT: ' + USDT)

            price = (USDT / BABY)

            price = parseFloat(price)

            console.log('BabyPrice: ' + price)

            resolve(price);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getTfiLpPrice = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let price;

            let TFI = await new window.globalWeb3.eth.Contract(abiERC20, TrustFiToken).methods.balanceOf(TfiUsdtToken).call();
            let USDT = await new window.globalWeb3.eth.Contract(abiERC20, UsdtToken).methods.balanceOf(TfiUsdtToken).call();

            console.log('TFI: ' + TFI)
            console.log('USDT: ' + USDT)

            price = (USDT / TFI)

            price = parseFloat(price)

            console.log('TfiPrice: ' + price)

            resolve(price);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getMilkLpPrice = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let price;

            let MILK = await new window.globalWeb3.eth.Contract(abiERC20, MilkToken).methods.balanceOf(MilkUsdtBabyLpToken).call();
            let USDT = await new window.globalWeb3.eth.Contract(abiERC20, UsdtToken).methods.balanceOf(MilkUsdtBabyLpToken).call();

            console.log('MILK: ' + MILK)
            console.log('USDT: ' + USDT)

            price = (USDT / MILK)

            price = parseFloat(price)

            console.log('MilkPrice: ' + price)

            resolve(price);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getAPR = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let APR;

            let TVL = await getTVL(contract);
            TVL = parseFloat(TVL);

            let dailyReward = await getDailyReward(contract);
            dailyReward = parseFloat(dailyReward);

            let price;
            if (contract === '0xb923919fa6Df7675aa33069425aD42878BF39206' // tfi
                ||
                contract === '0xa0668C22d2808e7d83c8a3B16A45052aEb2F0768'
                ||
                contract === '0x5241CF262C73f321C12683196B9a83C6f444e2ad'
                ||
                contract === '0x88FE77Bd9139387533E5447Fe99fD4Df7a6EaE9e'
                ||
                contract === '0xf3aAB863b69098FD191c1a20fCB9ac8b1Af42be7'
            ) {
                price = await getLpPrice();
            } else if (contract === '0xd11EC60CeC0FcB1b74D9Fb15cDa0aF65e7882e35') { // metax
                price = await getMetaxLpPrice();
            } else if (contract === '0x2EAAF0fBECf731A72E589DE08ef6F73766EF286e') { // baby
                price = await getBabyLpPrice();
            } else if (
                contract === '0x9eaf92e7F1d291fC53289322CDBcF8751A27CB50'
                ||
                contract === '0x9344D3bf77e3ecd6CB8666A4E78FD26Fe5a1946f'
            ) { // milk
                price = await getMilkLpPrice();
            } else if (contract === '0x0a62D95739958001C7eeA5fDDbcF3ca7fb4494ba') { // cheers
                price = await getCheersBuLpPrice();
            } else if (contract === '0xfaE64A6691dA9eD1cBd700c1E0d3D8625B865856') { // polc
                price = await getPolcPrice();
            } else if (contract === '0x825CDDD8B76b53D665952F19c29C902c663501Dc') { // moni
                price = await getMoniPrice();
            } else if (contract === '0x1Dd1D8972C3F803DC2333cF6B613A827651d6263') { // aog
                price = await getAogPrice();
            } else { // cheers
                price = await getCheersPrice(store.state.priceAddress);
            }
            price = parseFloat(price);

            if (dailyReward > 0 && price > 0 && TVL > 0) {
                APR = dailyReward * 365 * price / TVL
            } else {
                APR = 0
            }
            APR = parseFloat(APR)

            resolve(APR);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getTVL = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res;
            let price;

            if (contract === '0xa0668C22d2808e7d83c8a3B16A45052aEb2F0768') { // tfi-bnb
                res = await new window.globalWeb3.eth.Contract(abiERC20, TrustFiToken).methods.balanceOf(TfiBnbToken).call();
                price = await getLpPrice();
                let num = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.totalPower().call();
                let total = await new window.globalWeb3.eth.Contract(abiERC20, TfiBnbToken).methods.totalSupply().call();
                if (parseFloat(num) > 0) {
                    res = res * price * 2 * num / total;
                } else {
                    res = 0;
                }
            } else if (contract === '0x0a62D95739958001C7eeA5fDDbcF3ca7fb4494ba') {
                // 池子里的量/LP里总的量*LP里busd的数量*2
                let num = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.totalPower().call();
                let total = await new window.globalWeb3.eth.Contract(abiERC20, CheersBusdToken).methods.totalSupply().call();
                let busd = await new window.globalWeb3.eth.Contract(abiERC20, BusdToken).methods.balanceOf(CheersBusdToken).call();
                if (parseFloat(num) > 0) {
                    res = num / total * busd * 2;
                } else {
                    res = 0;
                }
            } else if (contract === '0x88FE77Bd9139387533E5447Fe99fD4Df7a6EaE9e') {
                // 池子里的量/LP里总的量*LP里busd的数量*2
                let num = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.totalPower().call();
                let total = await new window.globalWeb3.eth.Contract(abiERC20, MilkUsdtBabyLpToken).methods.totalSupply().call();
                let usdt = await new window.globalWeb3.eth.Contract(abiERC20, UsdtToken).methods.balanceOf(MilkUsdtBabyLpToken).call();
                if (parseFloat(num) > 0) {
                    res = num / total * usdt * 2;
                } else {
                    res = 0;
                }
            } else {
                res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.totalPower().call();
                if (
                    contract === '0xb923919fa6Df7675aa33069425aD42878BF39206'
                    ||
                    contract === '0x5241CF262C73f321C12683196B9a83C6f444e2ad'
                    ||
                    contract === '0xf3aAB863b69098FD191c1a20fCB9ac8b1Af42be7'
                    ||
                    contract === '0xfaE64A6691dA9eD1cBd700c1E0d3D8625B865856'
                    ||
                    contract === '0x825CDDD8B76b53D665952F19c29C902c663501Dc'
                    ||
                    contract === '0x1Dd1D8972C3F803DC2333cF6B613A827651d6263'
                ) { // tfi
                    price = await getLpPrice();
                } else if (contract === '0xd11EC60CeC0FcB1b74D9Fb15cDa0aF65e7882e35') { // metax
                    price = await getMetaxLpPrice();
                } else if (contract === '0x2EAAF0fBECf731A72E589DE08ef6F73766EF286e') { // baby
                    // price = await getBabyLpPrice();
                    price = await getTfiLpPrice();
                } else if (contract === '0x9eaf92e7F1d291fC53289322CDBcF8751A27CB50') { // milk
                    // price = await getMilkLpPrice();
                    price = await getTfiLpPrice();
                } else { // cheers
                    price = await getCheersPrice(store.state.priceAddress);
                }
                res = res * price;
            }

            res = BigNumberDiv(res, 1e18)

            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getTVLAll = (contracts) => {
    return new Promise(async (resolve, reject) => {
        try {
            let count = 0;
            let price = 0;

            for (let i = 0; i < contracts.length; i++) {
                let res = 0;
                if (contracts[i] === '0xa0668C22d2808e7d83c8a3B16A45052aEb2F0768') {
                    res = await new window.globalWeb3.eth.Contract(abiERC20, TrustFiToken).methods.balanceOf(TfiBnbToken).call();
                    price = await getLpPrice();
                    let num = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contracts[i]).methods.totalPower().call();
                    let total = await new window.globalWeb3.eth.Contract(abiERC20, TfiBnbToken).methods.totalSupply().call();
                    if (parseFloat(num) > 0) {
                        res = res * price * 2 * num / total;
                    } else {
                        res = 0;
                    }
                } else if (contracts[i] === '0x0a62D95739958001C7eeA5fDDbcF3ca7fb4494ba') {
                    // 池子里的量/LP里总的量*LP里busd的数量*2
                    let num = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contracts[i]).methods.totalPower().call();
                    let total = await new window.globalWeb3.eth.Contract(abiERC20, CheersBusdToken).methods.totalSupply().call();
                    let busd = await new window.globalWeb3.eth.Contract(abiERC20, BusdToken).methods.balanceOf(CheersBusdToken).call();
                    if (parseFloat(num) > 0) {
                        res = num / total * busd * 2;
                    } else {
                        res = 0;
                    }
                } else if (contracts[i] === '0x88FE77Bd9139387533E5447Fe99fD4Df7a6EaE9e') {
                    // 池子里的量/LP里总的量*LP里busd的数量*2
                    let num = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contracts[i]).methods.totalPower().call();
                    let total = await new window.globalWeb3.eth.Contract(abiERC20, MilkUsdtBabyLpToken).methods.totalSupply().call();
                    let usdt = await new window.globalWeb3.eth.Contract(abiERC20, UsdtToken).methods.balanceOf(MilkUsdtBabyLpToken).call();
                    if (parseFloat(num) > 0) {
                        res = num / total * usdt * 2;
                    } else {
                        res = 0;
                    }
                } else {
                    if (
                        contracts[i] === '0xb923919fa6Df7675aa33069425aD42878BF39206'
                        ||
                        contracts[i] === '0x5241CF262C73f321C12683196B9a83C6f444e2ad'
                        ||
                        contracts[i] === '0xf3aAB863b69098FD191c1a20fCB9ac8b1Af42be7'
                        ||
                        contracts[i] === '0xfaE64A6691dA9eD1cBd700c1E0d3D8625B865856'
                        ||
                        contracts[i] === '0x825CDDD8B76b53D665952F19c29C902c663501Dc'
                        ||
                        contracts[i] === '0x1Dd1D8972C3F803DC2333cF6B613A827651d6263'
                    ) { // tfi
                        price = await getLpPrice();
                    } else if (contracts[i] === '0xd11EC60CeC0FcB1b74D9Fb15cDa0aF65e7882e35') { // metax
                        price = await getMetaxLpPrice();
                    } else if (contracts[i] === '0x2EAAF0fBECf731A72E589DE08ef6F73766EF286e') { // baby
                        // price = await getBabyLpPrice();
                        price = await getTfiLpPrice();
                    } else if (contracts[i] === '0x9eaf92e7F1d291fC53289322CDBcF8751A27CB50') { // milk
                        // price = await getMilkLpPrice();
                        price = await getTfiLpPrice();
                    } else { // cheers
                        price = await getCheersPrice(store.state.priceAddress);
                    }
                    res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contracts[i]).methods.totalPower().call();
                    res = res * price;
                }

                res = BigNumberDiv(res, 1e18)
                count += parseFloat(res);
            }
            resolve(count);
        } catch (err) {
            reject(err);
        }
    });
}





const getDailyReward = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.getDailyReward().call();
            res = BigNumberDiv(res, 1e18);
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getFeeRatio = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.feeRatio().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getIsUserStake = async (contract, user) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.isUserStake(user).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getPoolLimitInfo = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let obj = {}

            obj.isPledgeRestriction = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.isPledgeRestriction().call();
            obj.isSetPoolQuota = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.isSetPoolQuota().call();
            obj.poolQuotaLimit = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.poolQuotaLimit().call();
            obj.isSetUserQuota = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.isSetUserQuota().call();
            obj.userQuotaLimit = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.userQuotaLimit().call();
            obj.totalPower = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.totalPower().call();

            obj.poolQuotaLimit = BigNumberDiv(obj.poolQuotaLimit, 1e18);
            obj.userQuotaLimit = BigNumberDiv(obj.userQuotaLimit, 1e18);
            obj.totalPower = BigNumberDiv(obj.totalPower, 1e18);

            resolve(obj);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const isMortgage = async (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.isMortgage(account).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const isStart = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.isStart().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 投资
const participateExchange = (contract, account, amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit('setState', {
                toastShow: true,
                toastShowType: 0,
            })

            let inputAmount = BigNumberMul(amount, Math.pow(10, 18));

            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.participateExchange(inputAmount).send({
                from: account,
                gas: 2000000
            }).on('transactionHash', function (hash) {
                popupPrompts(1, hash)
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash)
            });
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    });
}

const isThreshold = (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.isThreshold(account).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const isRefundPeriod = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdosPool, contract).methods.isRefundPeriod().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getPoolAddressArray = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdosPool, contract).methods.getPoolAddressArray().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getThresholdByIndex = (contract, pool, index) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdosPool, contract).methods.getThresholdByIndex(pool, index).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getRemainingFundraising = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdosPool, contract).methods.getRemainingFundraising().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getIsOverRaising = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdosPool, contract).methods.isOverRaising().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getIsOpenClaim = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdosPool, contract).methods.isOpenClaim().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const userIsClaim = (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.userIsClaim(account).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 领取
const claim = (contract, account, igoType, isRefund) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit('setState', {
                toastShow: true,
                toastShowType: 0,
            })
            let res;
            if (igoType === 'one') {
                res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.claim().send({
                    from: account,
                    gas: 2000000
                }).on('transactionHash', function (hash) {
                    popupPrompts(1, hash)
                }).on('confirmation', function (confirmationNumber, receipt) {
                    store.commit('setState', {
                        transactionHash: receipt.transactionHash,
                    })
                }).on('receipt', function (receipt) {
                    popupPrompts(2, receipt.transactionHash)
                });
            } else {
                res = await new window.globalWeb3.eth.Contract(abiFundraisingIdosPool, contract).methods.claim(isRefund).send({
                    from: account,
                    gas: 2000000
                }).on('transactionHash', function (hash) {
                    popupPrompts(1, hash)
                }).on('confirmation', function (confirmationNumber, receipt) {
                    store.commit('setState', {
                        transactionHash: receipt.transactionHash,
                    })
                }).on('receipt', function (receipt) {
                    popupPrompts(2, receipt.transactionHash)
                });
            }
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    });
}

// 管理员领取
const ownerClaim = (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit('setState', {
                toastShow: true,
                toastShowType: 0,
            })
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.ownerClaim().send({
                from: account,
                gas: 2000000
            }).on('transactionHash', function (hash) {
                popupPrompts(1, hash)
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash)
            });
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    });
}

// 获取投资总人数
const getNumberParticipants = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.getNumberParticipants().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 获取个人投资信息
const getExchangeInfo = (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.getExchangeInfo(account).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 获取私募池信息
const getExchangePoolDetails = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.getExchangePoolDetails().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 获取时间差
const remainingTime = (contract, timeType) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.remainingTime(timeType).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const isWhiteList = (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.isWhiteList(account).call();
            res = parseInt(res)
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 查询个人奖励
const earned = (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.earned(account).call();
            res = parseFloat(BigNumberDiv(res, 1e18));
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const earnedAll = (contracts, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let count = 0;
            for (let i = 0; i < contracts.length; i++) {
                let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contracts[i]).methods.earned(account).call();
                res = parseFloat(BigNumberDiv(res, 1e18));
                count += res;
            }
            resolve(count);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 抵押量
const power = (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.power(account).call();
            res = BigNumberDiv(res, 1e18);
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 质押
const stake = (contract, account, amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit('setState', {
                toastShow: true,
                toastShowType: 0,
            })

            let cheersAmount = BigNumberMul(amount, Math.pow(10, 18));

            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.stake(cheersAmount).send({
                from: account,
                gas: 2000000
            }).on('transactionHash', function (hash) {
                popupPrompts(1, hash)
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash)
            });
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    });
}

// 赎回
const withdraw = (contract, account, amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit('setState', {
                toastShow: true,
                toastShowType: 0,
            })

            let cheersAmount = BigNumberMul(amount, Math.pow(10, 18));

            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.withdraw(cheersAmount).send({
                from: account,
                gas: 2000000
            }).on('transactionHash', function (hash) {
                popupPrompts(1, hash)
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash)
            });
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    });
}

// 领取奖励
const receiveRewards = (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit('setState', {
                toastShow: true,
                toastShowType: 0,
            })
            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.receiveRewards().send({
                from : account,
                gas: 2000000
            }).on('transactionHash', function (hash) {
                popupPrompts(1, hash)
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash)
            });
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    });
}

// 获取倒计时
const getUserPunishTime = (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.getUserPunishTime(account).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 查询时间差
const miningRemainingTime = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiCheersLpPool, contract).methods.remainingTime().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 查询授权额度
const coinAllowanceCheers = (token, contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiERC20, token).methods.allowance(account, contract).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
};

// 挖矿页 - 抵押 - 授权
const coinApproveCheersMortgage = (token, contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit('setState', {
                toastShow: true,
                toastShowType: 0,
            })
            const res = await new window.globalWeb3.eth.Contract(abiERC20, token).methods.approve(contract, "500000000000000000000000000000").send({
                from: account,
            }).on('transactionHash', function (hash) {
                popupPrompts(1, hash)
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash)
            })
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    });
};

const isStartFundRaising = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.isStart().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
};

const isEndFundRaising = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.isEnd().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
};

const getFundEndingTime = async (contract) => {
    return new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.endTime().call();
}

const isClaimFundRaising = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.isClaim().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
};

const isAdminFundRaising = (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.isAdmin().call({
                from: account,
            });
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
};

const getThreshold = (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiFundraisingIdoPool, contract).methods.getThreshold().call();
            res = BigNumberDiv(res, 1e18);
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 查询授权额度
const coinAllowanceFundRaising = (token, contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiERC20, token).methods.allowance(account, contract).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
};

// 私募页 - 抵押 - 授权
const coinApproveFundRaising = (token, contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit('setState', {
                toastShow: true,
                toastShowType: 0,
            })
            const res = await new window.globalWeb3.eth.Contract(abiERC20, token).methods.approve(contract, "20000000000000000000000000").send({
                from: account,
            }).on('transactionHash', function (hash) {
                popupPrompts(1, hash)
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash)
            })
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    });
};

const getIsPublic = async (contractAddr) => {
    let isPublic = false
    try {
        isPublic = await new window.globalWeb3.eth.Contract(abiPublicFundraisingPool, contractAddr).methods.isPublic().call();
    } catch (e) {
        // do nothing
    }
    return isPublic
}

export {
    // 连接钱包
    initWeb3,
    initWeb3hooks,
    initParams,
    // 弹窗
    popupPrompts,
    // 主要方法
    getCheersTotalSupply,
    getCheersBalanceOf,
    getLpBalanceOf,
    getCheersPrice,
    getLpPrice,
    getPolcPrice,
    getMoniPrice,
    getAogPrice,
    getCheersBuLpPrice,
    getBabyLpPrice,
    getTfiLpPrice,
    getMilkLpPrice,
    getAPR,
    getTVL,
    getTVLAll,
    participateExchange,
    isThreshold,
    isRefundPeriod,
    getPoolAddressArray,
    getThresholdByIndex,
    getRemainingFundraising,
    getIsOverRaising,
    getIsOpenClaim,
    userIsClaim,
    claim,
    ownerClaim,
    getNumberParticipants,
    getExchangeInfo,
    getExchangePoolDetails,
    remainingTime,
    isWhiteList,
    earned,
    earnedAll,
    power,
    stake,
    withdraw,
    receiveRewards,
    getDailyReward,
    getFeeRatio,
    getIsUserStake,
    getPoolLimitInfo,
    isMortgage,
    isStart,
    getUserPunishTime,
    miningRemainingTime,
    coinAllowanceCheers,
    coinApproveCheersMortgage,
    isStartFundRaising,
    isEndFundRaising,
    isClaimFundRaising,
    isAdminFundRaising,
    getThreshold,
    coinAllowanceFundRaising,
    coinApproveFundRaising,
    getIsPublic,
    getFundEndingTime
}
