import store from '../store';
import Toast from 'vant/lib/toast';
import 'vant/lib/toast/style';
import Dialog from 'vant/lib/dialog';
import 'vant/lib/dialog/style';
import {BigNumber} from './bignumber.js';

function BigNumberStr(num1, num2, col) {
    return new BigNumber(num1).dividedBy(Math.pow(10, num2)).toFixed(col, 1);
}

function BigNumberMul(num1, num2, col) {//乘法
    return new BigNumber(num1).multipliedBy(num2).toFixed(col, 1);
}

function BigNumberDiv(num1, num2, col) {//除法
    return new BigNumber(num1).dividedBy(num2).toFixed(col, 1);
}

function BigNumberAdd(num1, num2, col) {//加法
    return new BigNumber(num1).plus(num2).toFixed(col, 1);
}

function BigNumberSub(num1, num2, col) {//减法
    return new BigNumber(num1).minus(num2).toFixed(col, 1);
}

const lang = (key) => {
    let langs = store.state.lang;
    let item = store.state.languageType;
    return langs[item][key];
};

const setUnit = (num = 0, decimal = 6) => {
    const multiple = Math.pow(10, decimal)
    let rs = parseFloat(Math.floor(num * multiple) / multiple) || 0;
    if (!(typeof rs === 'number' && isFinite(rs))) {
        rs = 0;
    }
    return rs;
}

const cutAccount = (text, num, isXing) => {
    if (!text) return '';
    return text.substr(0, num) + (isXing ? '*****' : '...') + text.substr(text.length - num, text.length);
};

export {
    Toast,
    Dialog,
    BigNumberStr,
    BigNumberMul,
    BigNumberDiv,
    BigNumberAdd,
    BigNumberSub,
    lang,
    setUnit,
    cutAccount
}