import axios from 'axios'

// 访问连接配置
var baseURL = 'https://cheersland.org/encodeInvitedCode'

var restDataString = function (restData) {
    let dataString = ''
    if (restData) {
        for (let data in restData) {
            dataString += '/' + restData[data]
        }
    }
    return dataString
}

const send = function (address, restData) {
    return new Promise((resolve, reject) => {
        axios.get(
            baseURL + '?address=' + address + restDataString(restData),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

var igoRankingURL = 'https://rank.cheersland.org'

// igoRankingURL = 'http://127.0.0.1:7009'

const add = function (type, address) {
    return new Promise((resolve, reject) => {
        axios.get(
            igoRankingURL + (type === 'cheers' ? '/cheers/addRanking' : '/tfi/addRanking') + '?user=' + address + '&time=' + new Date().getTime(),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                responseType: 'json'
            }
        ).then(res => {
            let { data } = res
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

const queryRanking = function (type, total, address) {
    return new Promise((resolve, reject) => {
        axios.get(
            igoRankingURL + (type === 'cheers' ? '/cheers/queryRanking?tokey=cheers666' : '/tfi/queryRanking?tokey=tfi666') + '&time=' + new Date().getTime() + '&total=' + total + '&user=' + address,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                responseType: 'json'
            }
        ).then(res => {
            // console.log('接口返回数据: ' + JSON.stringify(res))
            let { data } = res
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}

const http = {
    send,
    add,
    queryRanking
}

export default http