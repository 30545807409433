import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './tools/request'
import 'jquery'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from "element-ui/lib/locale/lang/en";
import {Tabbar, TabbarItem, Button, Swipe, SwipeItem, Toast, Lazyload, Dialog} from 'vant';
import {DropdownMenu, DropdownItem} from 'vant';
import {Field} from 'vant';
import {Icon} from 'vant';
import {Slider} from 'vant';
import {Circle} from 'vant';
import {Popover} from 'vant';
import {Switch} from 'vant';
import {Collapse, CollapseItem} from 'vant';
import {setUnit} from "./tools";
import '@vant/touch-emulator';
import './assets/tailwind.css';

Vue.use(ElementUI, {locale});
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Slider);
Vue.use(Circle);
Vue.use(Popover);
Vue.use(Switch);
Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.config.productionTip = false
Vue.use(Tabbar).use(TabbarItem).use(Button).use(Swipe).use(SwipeItem).use(Toast).use(Lazyload).use(Dialog);
Vue.prototype.setUnit = setUnit;
Vue.prototype.$http = request

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

