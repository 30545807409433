// debug  true:测试  false:正式, "54.254.254.47"
const devUrl = ["localhost", "127.0.0.1", "192.168.2.29"];

const getEnv = () => {
    const link = window.location.href;
    let isDebug = false;
    for (let k in devUrl) {
        if (link.indexOf(devUrl[k]) !== -1) {
            isDebug = true;
        }
    }
    return isDebug;
}

// let debug = getEnv();
let debug = false;

/* 正式环境 */
// 合约地址
let CheersToken = '0xbBBcB350C64Fe974e5C42A55c7070644191823f3'; // 平台(CHEERS)币地址
let FundRaisingToken = '0x0d2A71996B347737EC6a6295d9eB5d07cB70e254'; // 募资(BIGO)代币地址
let TrustFiToken = '0x7565ab68D3F9DaDff127F864103C8c706Cf28235'; // 质押(TFI)代币地址
let MetaxToken = '0x03f8fdc10d5bcf7508375585b04e93d656d36954'; // 质押(Metax)代币地址
let BnbToken = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'; // BNB代币地址
let BusdToken = '0xe9e7cea3dedca5984780bafc599bd69add087d56'; // BUSD代币地址
let UsdtToken = '0x55d398326f99059ff775485246999027b3197955'; // 募资(USDT)代币地址
let BabyToken = '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657'; // BABY代币地址
let MilkToken = '0xbf37f781473f3b50e82c668352984865eac9853f'; // MILK代币地址
let LightYearToken = '0x39BD64E5b9e025b0C8D91ee2CFec9125A388894B'; // LightYear代币地址
let PolcToken = '0x6ae9701b9c423f40d54556c9a443409d79ce170a'; // POLC代币地址
let MoniToken = '0x9573c88aE3e37508f87649f87c4dd5373C9F31e0'; // MONI代币地址
let AogToken = '0x40c8225329bd3e28a043b029e0d07a5344d2c27c'; // AOG代币地址
let KktToken = ''; // KKT代币地址
let PolcBnbLpToken = '0x4689bf48cd12520e0eb5699964d37ddc5e96945b'; // POLC-BNB LP代币地址
let MoniBnbLpToken = '0xbcfd0d4a37feb4dceaaefa9da28cd833e5f04e9f'; // MONI-BNB LP代币地址
let AogBusdLpToken = '0x88c9bf5e334e2591c6a866d5e20683e31226be3d'; // AOG-BUSD LP合约地址
let TfiBnbToken = '0xB1c508f942C1d1B1A44384bcc92885e43887D0fA'; // TFI-BNB LP合约地址
let BnbBusdToken = '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16'; // BNB-BUSD LP合约地址
let MetaxUsdtToken = '0x829c113c942228a8c7e87519e6ee9fe1115fd62d'; // Metax-Usdt LP合约地址
let CheersBusdToken = '0xee244c7e1e92e803af29137df6fe623a8311bb7b'; // Cheers-Busd LP合约地址
let BabyUsdtToken = '0xe730c7b7470447ad4886c763247012dfd233baff'; // BABY-USDT合约地址
let TfiUsdtToken = '0xbe601abc8032f12daa0419de01bd81e4d94980bd'; // TFI-USDT合约地址
let MilkUsdtBabyLpToken = '0xDB6c248902AD2722A5E01E9689824c28bd0A5f75'; // MILK-USDT Baby LP合约地址
// 前缀
let OpenUrlSLD = 'bscscan.';

/* 测试环境 */
if (debug) {
    // 测试
    // 合约地址
    CheersToken = '0xb923919fa6Df7675aa33069425aD42878BF39206'; // 平台(CHEERS)币地址
    FundRaisingToken = '0x0d2A71996B347737EC6a6295d9eB5d07cB70e254'; // 募资(BIGO)代币地址
    TrustFiToken = '0x7565ab68D3F9DaDff127F864103C8c706Cf28235'; // 质押(TFI)代币地址
    MetaxToken = '0x03f8fdc10d5bcf7508375585b04e93d656d36954'; // 质押(Metax)代币地址
    BnbToken = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'; // BNB代币地址
    BusdToken = '0xe9e7cea3dedca5984780bafc599bd69add087d56'; // BUSD代币地址
    UsdtToken = '0x55d398326f99059ff775485246999027b3197955'; // 募资(USDT)代币地址
    BabyToken = '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657'; // BABY代币地址
    MilkToken = '0xbf37f781473f3b50e82c668352984865eac9853f'; // MILK代币地址
    LightYearToken = '0x39BD64E5b9e025b0C8D91ee2CFec9125A388894B'; // LightYear代币地址
    PolcToken = '0x6ae9701b9c423f40d54556c9a443409d79ce170a'; // POLC代币地址
    MoniToken = '0x9573c88aE3e37508f87649f87c4dd5373C9F31e0'; // MONI代币地址
    AogToken = '0x40c8225329bd3e28a043b029e0d07a5344d2c27c'; // AOG代币地址
    KktToken = ''; // KKT代币地址
    PolcBnbLpToken = '0x4689bf48cd12520e0eb5699964d37ddc5e96945b'; // POLC-BNB LP代币地址
    MoniBnbLpToken = '0xbcfd0d4a37feb4dceaaefa9da28cd833e5f04e9f'; // MONI-BNB LP代币地址
    AogBusdLpToken = '0x88c9bf5e334e2591c6a866d5e20683e31226be3d'; // AOG-BUSD LP合约地址
    TfiBnbToken = '0xB1c508f942C1d1B1A44384bcc92885e43887D0fA'; // TFI-BNB LP合约地址
    BnbBusdToken = '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16'; // BNB-BUSD LP合约地址
    MetaxUsdtToken = '0x829c113c942228a8c7e87519e6ee9fe1115fd62d'; // Metax-Usdt LP合约地址
    CheersBusdToken = '0xee244c7e1e92e803af29137df6fe623a8311bb7b'; // Cheers-Busd LP合约地址
    BabyUsdtToken = '0xe730c7b7470447ad4886c763247012dfd233baff'; // BABY-USDT合约地址
    TfiUsdtToken = '0xbe601abc8032f12daa0419de01bd81e4d94980bd'; // TFI-USDT合约地址
    MilkUsdtBabyLpToken = '0xDB6c248902AD2722A5E01E9689824c28bd0A5f75'; // MILK-USDT Baby LP合约地址
    // 前缀
    OpenUrlSLD = 'testnet.bscscan.';
}

export {
    OpenUrlSLD,
    CheersToken,
    FundRaisingToken,
    TrustFiToken,
    MetaxToken,
    BnbToken,
    BusdToken,
    UsdtToken,
    BabyToken,
    MilkToken,
    LightYearToken,
    PolcToken,
    MoniToken,
    AogToken,
    KktToken,
    PolcBnbLpToken,
    MoniBnbLpToken,
    AogBusdLpToken,
    TfiBnbToken,
    BnbBusdToken,
    MetaxUsdtToken,
    CheersBusdToken,
    BabyUsdtToken,
    TfiUsdtToken,
    MilkUsdtBabyLpToken
}
